/* .inventory-sfgfg-item .serial {
    width: 10%;
} */
.inventory-sfgfg-item .name {
    width: calc((100% - var(--serial-width)) * 0.5);
}

.inventory-sfgfg-item .unit {
    width: calc((100% - var(--serial-width)) * 0.125);
}

.inventory-sfgfg-item .lowthreshold {
    width: calc((100% - var(--serial-width)) * 0.125);
    text-align: right;
}
.inventory-sfgfg-item .projected-qty {
    width: calc((100% - var(--serial-width)) * 0.125);
    text-align: right;
}
.inventory-sfgfg-item .actual-qty {
    width: calc((100% - var(--serial-width)) * 0.125);
    text-align: right;
}
