.report-title {
    display: flex;
    justify-content: space-between;
}

.report-items-alt-color:nth-child(even) {
    background-color: var(--grey-l2);
}

.report-items {
    margin: 0;
    padding: 0.5rem 0;
}

@media screen and (max-width: 720px) {
    .report-items {
        margin-bottom: 1rem;
    }
}
