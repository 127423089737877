.subscriptin-button-flow {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .subscriptin-button-flow {
        flex-direction: row-reverse;
    }
}
