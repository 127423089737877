.rawmat-datalist-supplier {
    display: flex;
    align-items: center;
}

.rawmat-datalist-supplier .input-wrapper {
    width: 100%;
}
.rawmat-datalist-supplier i {
    font-size: 1.6rem;
    margin: 1rem 0 0 1rem;
    cursor: pointer;
}

.rm-suppliers-section .rss-label {
    font-size: 1.6rem;
    display: inline-block;
    margin-bottom: 1rem;
}

