.add-btn {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 0.3rem 1rem;
    display: inline-block;
    text-align: center;

    margin-left: 2rem;
    width: 5rem;
    cursor: pointer;

    user-select: none;
    background-color: var(--white);
    color: var(--primary-text-color);
    outline: transparent;
    border: 1px solid var(--grey-d1);
    border-radius: 2px;
}
.add-btn:hover,
.add-btn:focus {
    /* background-color: var(--primary-color-darken);
    border-color: var(--primary-color-darken); */

    background-color: var(--grey-l2);
}

.add-btn-disable {
    cursor: default;
    background-color: var(--grey-l1);
    border-color: #fff;
    /* color: var(--grey-d1); */
    color: #fff;
}
.add-btn-disable:hover {
    background-color: var(--grey-l1);
    border-color: #fff;
    /* color: var(--grey-d1); */
    color: #fff;
}
