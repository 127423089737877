.inv-transfer-choose-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    font-size: 1.4rem;
    height: 3.2rem;
    font-weight: 400;
    /* width: 14rem; */

    margin: 1rem 0;

    user-select: none;
    background-color: var(--white);
    color: var(--primary-text-color);
    outline: transparent;
    border: 1px solid var(--grey-d1);

    text-decoration: none;
    border-radius: 2px;
}

.inv-transfer-choose-btn:hover {
    background-color: var(--grey-l2);
}
