.sidenav {
    background-image: url('../../img/city.jpg');
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    height: 100vh;
    width: 26rem;
    position: sticky;
    top: 0;
    z-index: 10;
    user-select: none;
    overflow: auto;
    /* background-color: rgba(0, 0, 0, 0.8); */
}
/* .sidenav::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
} */

#sidenav::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    margin-left: 0.5rem;
}

#sidenav::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(255, 255, 255, 0.1);
}

#sidenav::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
}

.sidenav .brand-container {
    border-bottom: 1px solid var(--grey-d1);
    display: flex;
    flex-direction: column;
    z-index: 103;
}

.sidenav .brand {
    font-size: 2rem;
    font-weight: 400;
    /* border-bottom: 1px solid var(--grey-d1); */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    height: 6rem;
    color: var(--white);
    z-index: 103;
}
.sidenav .company-name {
    display: flex;
    justify-content: center;
    color: var(--white);
    margin-bottom: 1rem;
    letter-spacing: 0.1rem;
}

.sidenav .brand .brand-icon {
    font-size: 3rem;
    color: var(--sky);
    margin: 0 1.5rem;
}

.sidenav .links {
    color: var(--white);
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--text-standard);
    padding: 1rem 0.5rem;

    min-height: 50rem;
}

/* NEEDS TO BE REFACTORED ONCE ALL LINKS ARE WRAPPED WITH NAVLINK */
.sidenav .links .link,
.sidenav .links .link a,
.sidenav .group-link {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    border-radius: 4px;
    height: 4.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: all var(--transition-menu);
}

.sidenav .link-icon {
    font-size: 2rem;
    margin: 0 1rem;
}

.sidenav .links .link-logout {
    display: none;
}

.sidenav .group-link {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
}

.sidenav .group-link .group-link-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    border-radius: 4px;
    height: 4.5rem;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
}

.sidenav .group-link .group-link-head .arrow-icon {
    display: inline-block;
    margin-left: auto;
    margin-right: 0.5rem;
    transition: all 200ms linear;
}

.icon-spin {
    transform: rotateZ(180deg);
}

.sidenav .group-link .group-link-head:hover,
.sidenav .link:hover,
.sidenav .link:focus,
.activeLink {
    background-color: #424242;
}

.sidenav .group-link .group-link-body {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 200ms linear;
    border-radius: 4px;
    background-color: var(--grey-d1);
}
.sidenav .group-link .group-link-body {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: height 200ms linear;
    border-radius: 4px;
    background-color: rgba(37, 37, 37, 0.7);
}

.sidenav .group-link .group-link-body .link,
.sidenav .group-link .group-link-body .link a {
    margin: 0;
}

.sidenav .group-link .group-link-body .body-link {
    display: flex;
}
.sidenav .group-link .group-link-body .body-link .spacer {
    display: inline-block;
    margin-left: 4rem;
}

@media screen and (max-width: 1200px) {
    .sidenav .links .link-logout {
        display: flex;
    }
}
