/* .pdpo-form-add-supplier-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 1rem;
}

.pdpo-form-add-supplier-container > div {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.pdpo-form-add-supplier-container > div:nth-child(2) {
    position: relative;
    top: 5rem;
} */

.remove-sup {
    color: var(--red);
    cursor: pointer;
    margin-left: auto;
    font-size: 1.2rem;
    border-radius: 1000px;
    padding: 0.5rem 0.7rem;
    transition: all 0.1s linear;
}
.remove-sup:hover {
    background-color: rgba(255, 0, 0, 0.1);
    color: var(--red);
}

.pdpo-form-rm-items-preview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1rem;
    align-items: center;
    font-size: 1.6rem;
}

.pdpo-form-rm-items-preview .item-names {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.pdpo-form-rm-items-preview .item-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pdpo-form-rm-items-preview .item-inputs > span {
    flex-grow: 1;
    font-size: 1.4rem;
    font-weight: 700;
}
.pdpo-form-rm-items-preview .item-inputs > div {
    flex-grow: 1;
    padding-right: 1rem;
}

.pdpo-form-rm-items-preview .item-names span {
    font-size: 1.4rem;
    font-weight: 700;
}

.pdpo-total-price-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.pdpo-total-price-container div {
    display: flex;
    align-items: center;
}

.pdpo-total-price-container div .pdpo-tpc-value {
    display: inline-block;
    width: 25rem;
    margin: 0.2rem 3rem 0.2rem 0;
    text-align: center;
}

.pdpo-total-price-container div .pdpo-tpc-value input {
    text-align: center;
}
