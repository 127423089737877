.operation-item .item-uid {
    width: calc((100% - var(--serial-width)) * 0.2);
}

.operation-item .description {
    width: calc((100% - var(--serial-width)) * 0.4);
}

.operation-item .order-date {
    width: calc((100% - var(--serial-width)) * 0.2);
}
.operation-item .status {
    width: calc((100% - var(--serial-width)) * 0.2);
}
