.setup-sfgfg-item .name {
    width: calc((100% - var(--serial-width)) * 0.5);
}

.setup-sfgfg-item .code {
    width: calc((100% - var(--serial-width)) * 0.25);
}
.setup-sfgfg-item .price {
    width: calc((100% - var(--serial-width)) * 0.25);
    text-align: right;
}
