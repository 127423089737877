/* Login Page */

.login-page {
    height: 100vh;
    min-height: 62rem;
    background-image: url('../../img/loginBg.jpg');
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-page::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    /* box-shadow: inset 0px 0px 25rem #000; */
    z-index: -1;
}

.login-page .brand {
    margin-bottom: 3rem;
    font-size: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page .brand .brand-icon {
    margin: 0 2rem 0 0;
    color: var(--sky);
}
.login-page .brand .brand-text {
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #fff;
}

@media screen and (max-width: 720px) {
    .login-page .brand .brand-text {
        font-size: 2.6rem;
    }
}

.login-page .terms {
    font-size: 1.3rem;
    letter-spacing: 1.5px;
    color: var(--grey-l1);
    margin-top: 3rem;
    cursor: pointer;
}

.login-page .terms:hover {
    color: var(--primary-color);
}

/* Login Form */

.login-form {
    background-image: linear-gradient(
        to bottom,
        rgba(41, 98, 255, 0.4),
        rgba(41, 98, 255, 0.2),
        rgba(66, 165, 245, 0.1)
    );

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 37rem;
    height: 40rem;
    background-color: #fff;
    border-radius: 2px;
    border: none;

    font-size: 1.4rem;
    overflow: hidden;

    position: relative;
}

.login-form .lgf-heading {
    margin: 5rem 5rem 3rem 5rem;
    font-size: 2.3rem;
    line-height: 3.5rem;
}

.login-form .lgf-text-link {
    font-size: 1.2rem;
    align-self: flex-end;
    margin-bottom: 2rem;
    margin-right: 4.5rem;
    color: var(--primary-color);
    cursor: pointer;
}

.login-form .email-sent-redirect-link {
    color: var(--primary-color);
    cursor: pointer;
}

.login-form .lgf-text-link:hover,
.login-form .email-sent-redirect-link:hover {
    color: var(--primary-color-darken);
}

.login-form .lgf-btn-ctrl {
    margin-top: 1rem;
    width: 28rem;
}

.login-form .lgf-error-message {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--red);
    margin-top: 1rem;
    padding: 0.5rem 4.5rem;
    max-height: 7rem;
    overflow: hidden;
}

.login-form .password-error-message {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--red);
    margin: 1rem 0 3rem 0;
    padding: 0.5rem 4.5rem;
    height: 8rem;
    overflow: hidden;
}

.login-form .redirect-text {
    position: absolute;
    bottom: 3rem;
}

@media screen and (max-width: 460px) {
    .login-page {
        flex-direction: flex-start;
    }

    .login-page .brand {
        width: 90%;
        font-size: 3.5rem;
    }

    .login-page .login-form {
        width: 90%;
    }

    .login-form .lgf-heading {
        margin: 5rem 3rem 3rem 3rem;
    }

    .login-form .lgf-text-link {
        margin-right: 2.3rem;
    }
}
