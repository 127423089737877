.setup-sup-cus-item .name {
    width: calc((100% - var(--serial-width)) * 0.4);
}

.setup-sup-cus-item .month {
    width: calc((100% - var(--serial-width)) * 0.2);
}

.setup-sup-cus-item .year {
    width: calc((100% - var(--serial-width)) * 0.2);
}
.setup-sup-cus-item .total {
    width: calc((100% - var(--serial-width)) * 0.2);
    text-align: right;
}
