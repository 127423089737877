.choose-sup-button,
.pdsi-po-or-dr-btn-control {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    font-size: 1.4rem;
    height: 3.2rem;
    font-weight: 400;
    width: 14rem;

    margin: 0 0 0 1rem;

    user-select: none;
    background-color: var(--white);
    color: var(--primary-text-color);
    outline: transparent;
    border: 1px solid var(--grey-d1);

    text-decoration: none;
    border-radius: 2px;
}

.choose-sup-button:hover,
.pdsi-po-or-dr-btn-control:hover {
    background-color: var(--grey-l2);
}

.pdsi-po-or-dr-btn-control {
    width: 25%;
    margin: 0;
}
