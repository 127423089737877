.collection-item {
    display: flex;
    height: 4rem;
    background-color: #fff;
    padding: 0 1rem;
    align-items: center;

    /* margin: 0.5rem 0; */
    z-index: 1;
    max-width: 100%;
    /* word-break: break-all; */
}

.collection-item:hover {
    box-shadow: var(--shadow);
    background-color: var(--grey-l2);
    z-index: 2;
    cursor: pointer;
}

.collection-item > div {
    padding-right: 1rem;
}

.list-table-heading {
    margin: 0 0 0.5rem 0;
    background-color: var(--blue);
    border-radius: 2px;
    color: var(--grey-d2);
    letter-spacing: 0.5px;
    z-index: 9;
    position: sticky;
    top: 12.7rem;
    width: 100%;
}

.collection-item .collection-item-entry .entry-label {
    display: none;
    color: var(--primary-text-color);
    width: 100%;
    text-align: left;
}

/* fix right justify of span by making with 100% and adding utility class */
.collection-item .collection-item-entry span {
    display: inline-block;
    width: 100%;
}

.serial {
    width: 8rem;
}

.list-table-heading:hover {
    box-shadow: none;
    background-color: var(--blue);
    cursor: default;
}

@media screen and (max-width: 850px) {
    .collection-item {
        flex-direction: column;
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        margin: 1rem auto 0;
        box-shadow: var(--shadow);
        padding: 1.5rem 1rem 1rem;
    }

    .collection-item:hover {
        box-shadow: var(--shadow-hover-low);
        background-color: #fff;
    }

    .collection-item > div.collection-item-entry {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
    }

    .collection-item > div.collection-item-entry .entry-label {
        display: inline-block;
        width: 11.5rem;
        font-weight: 700;
        color: var(--primary-text-color);
    }

    .list-table-heading {
        display: none;
    }
}
