.add-cc-container {
    margin-bottom: 4rem;
}

.card-input-container {
    padding: 1rem;
    border: 1px solid var(--grey-d1);
    border-radius: 2px;
    max-width: 45rem;
    min-height: 4rem;
    margin-bottom: 1rem;
}

.cc-row {
    grid-template-columns: minmax(0, 6fr) minmax(0, 4fr);
    align-items: center;
}
