.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.checkbox-label input {
    margin-right: 1rem;
    position: relative;
    top: -0.2rem;
    cursor: pointer;
}

.checkbox-label input:disabled {
    cursor: default;
}

.checkbox-hover-disable {
    cursor: default;
}
