.help-tip {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 1rem;
    position: relative;
    cursor: pointer;
}

.help-tip .ht-mark {
    padding: 0.1rem 0.4rem 0 0.4rem;
    font-weight: 400;
    font-size: 1.1rem;
    color: var(--grey);
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 1000px;
    border: 1px solid var(--grey-l1);

    transition: all 0.2s linear;
}

.help-tip .ht-mark:hover {
    font-weight: 700;
    color: black;
    border: 1px solid var(--grey);
}

.warning-tip {
    font-size: 2rem;
    color: darkorange;
    cursor: pointer;
    display: inline-block;
}

.info-tip {
    display: inline-block;
    margin: 0 1rem;

    font-weight: 700;
    text-align: center;
    color: var(--primary-color);
}

.info-tip .info-mark {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 100px;
    width: 2rem;
    height: 2rem;
    line-height: 2.5rem;
    font-size: 1.6rem;
    cursor: pointer;
}
