.select-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.select-wrapper label {
    font-size: var(--text-notice);
    margin: 0.5rem 0 0.3rem 0;
}

.select-wrapper .select-input {
    min-width: 20rem;
    width: 100%;
    /* height: 3.6rem; */
    background-color: var(--white);
    border-radius: 2px;
    outline-color: var(--primary-color-darken);
    border: 1px solid var(--primary-text-color);
    padding: 0.8rem 1rem;
}

.select-wrapper .select-input:focus {
    /* outline: 1px solid var(--primary-color-darken); */
    border: 1px solid transparent;
    outline-style: solid;
    outline-width: 2px;
}

.select-wrapper .select-error {
    padding-left: 0;
    margin: 3px 5px 5px 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}
