.input-wrapper {
    display: flex;
    flex-direction: column;
    color: inherit;
    padding: 0.2rem;
    user-select: none;
    position: relative;
    font-size: var(--text-standard);
}

.input-wrapper input {
    /* margin: 0; */
    width: inherit;
    font-size: inherit;
    color: inherit;
    -webkit-font-smoothing: antialiased;

    padding: 0.7rem 1rem;
    outline-color: var(--primary-color-darken);
    border: 1px solid var(--primary-text-color);
    border-radius: 2px;
    position: relative;
    max-height: 3.8rem;
}

.input-wrapper input:focus {
    border: 1px solid transparent;
    outline-style: solid;
    outline-width: 2px;
    z-index: 3;
}

.input-wrapper input:disabled {
    background-color: var(--grey-l1);
}

.input-wrapper .input-label {
    padding-left: 0;
    margin: 5px 5px 3px 0;
    font-size: var(--text-error);
    line-height: 1.2rem;
}
.input-wrapper .input-error {
    padding-left: 0;
    margin: 3px 5px 5px 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}

.input-wrapper .input-and-datalist-container {
    position: relative;
    width: 100%;
}

.input-wrapper .datalist {
    position: absolute;
    top: 3.5rem;
    left: 0;
    width: 100%;
    height: 15rem;
    z-index: 2;
    background-color: var(--white);
    border-radius: 2px;
    border: 1px solid var(--primary-text-color);
    overflow-y: auto;
    overflow-wrap: break-word;
    padding: 0 1.5rem;
    margin-bottom: 2rem;
}
.input-wrapper .datalist .option {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border-radius: 2px;
    cursor: pointer;
    z-index: 10;
}
.input-wrapper .datalist .option:hover {
    background-color: var(--grey-l2);
}

.input-wrapper .datalist .no-match-found {
    margin: 1rem;
    color: var(--red);
}

.input-wrapper .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: 2;
}

.input-wrapper input[type='date'] {
    padding: 0.5rem 1rem;
}

.select-wrapper select.select-input {
    padding: 0.6rem 1rem;
}

/* 

pagination styles for pagination when inside data list

*/

.input-wrapper .pagination-container {
    padding: 0.5rem;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.input-wrapper .pagination-container .pagination-btns {
    border-radius: 2px;
    background-color: inherit;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
}

.input-wrapper .pagination-container .pagination-btns i {
    padding: 0.6rem;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 100px;
    background-color: var(--grey-l2);
    margin: 0 0.3rem;
    font-size: 0.8rem;
}
.input-wrapper .pagination-container .pagination-btns .pagination-info {
    display: inline-block;
    margin: 0 1rem;
}
.input-wrapper .pagination-container .pagination-btns i:hover {
    background-color: var(--grey-l1);
}
.input-wrapper .pagination-container .pagination-btns i:active {
    color: var(--primary-color);
}

.input-wrapper input {
    width: 100%;
}
