.prod-rmItem {
    display: flex;
    align-items: center;
    margin-top: 2px;
}
.prod-rmItem .group {
    width: 5%;
    justify-content: center;
    text-align: center;
}
.prod-rmItem .itemName {
    width: 45%;
}
.prod-rmItem .input {
    width: 24%;
}

.prod-rmItem .input div {
    width: 70%;
    display: inline-block;
}

.prod-rmItem .unit {
    width: 24%;
    padding-left: 1rem;
}

.group-used-total {
    display: flex;
    margin: 1rem 0;
    border-bottom: 1px solid var(--grey-l1);
    padding-bottom: 1rem;
}

.group-used-total .total-used {
    margin-left: 50%;
    margin-right: 2rem;
}

.group-used-total .denominator {
    margin-right: 2rem;
}

.sfg-group {
    margin: 0.5rem 1rem;
    border: 1px solid var(--grey);
}
