/* .setup-sup-cus-item .inv-doc-id {
    width: calc((100% - var(--serial-width)) * 0.3);
} */

.setup-sup-cus-item .inv-invoice-number {
    width: calc((100% - var(--serial-width)) * 0.4);
}

.setup-sup-cus-item .inv-billing-cycle {
    width: calc((100% - var(--serial-width)) * 0.4);
}

.setup-sup-cus-item .inv-price {
    width: calc((100% - var(--serial-width)) * 0.2);
}

.setup-sup-cus-item .inv-status {
    width: calc((100% - var(--serial-width)) * 0.2);
}
