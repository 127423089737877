.textarea-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 0;
    user-select: none;
}

.textarea-wrapper textarea {
    margin: 0;

    padding: 1rem;
    outline-color: var(--primary-color-darken);
    border: 1px solid var(--primary-text-color);
    border-radius: 2px;
    height: 8rem;
    resize: none;
}

.textarea-wrapper textarea:focus {
    border: 1px solid transparent;
    outline-style: solid;
    outline-width: 2px;
}

.textarea-wrapper .textarea-label {
    padding-left: 0;
    margin: 5px 5px 3px 0;
    font-size: var(--text-notice);
    line-height: 1.2rem;
}
.textarea-wrapper .textarea-error {
    padding-left: 0;
    margin: 3px 5px 5px 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}
