.pagination-container {
    /* padding: 0.5rem; */
    margin: 0.5rem 0;
    display: flex;
    /* justify-content: flex-end; */
    font-size: 1.2rem;
}

.pagination-container .pagination-btns {
    border-radius: 2px;
    background-color: var(--grey-l1);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
}

.pagination-container .pagination-btns i {
    padding: 0.6rem;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 100px;
    background-color: var(--grey-l2);
    margin: 0 0.3rem;
}
.pagination-container .pagination-btns .pagination-info {
    display: inline-block;
    margin: 0 1rem;
}
.pagination-container .pagination-btns i:hover {
    background-color: var(--grey-l1);
}
.pagination-container .pagination-btns i:active {
    color: var(--primary-color);
}
