.input-icon-wrapper {
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 0;
    /* margin: 0.5rem 0; */
    user-select: none;
    width: 28rem;
    position: relative;
}

.input-icon-wrapper input {
    width: inherit;
    font-size: inherit;
    color: inherit;
    -webkit-font-smoothing: antialiased;

    padding: 1rem 1rem 1rem 4rem;
    outline-color: var(--primary-color-darken);
    border: 1px solid var(--primary-text-color);
    border-radius: 2px;
}

.input-icon-wrapper input:focus {
    border: 1px solid var(--primary-color-darken);
    outline-style: solid;
    outline-width: 2px;
}

.input-icon-wrapper .ii-icon-container {
    font-size: 2rem;
    color: var(--primary-color-darken);
    position: absolute;
    top: 0.7rem;
    left: 1.3rem;
}

.input-icon-wrapper .ii-input-error {
    padding-left: 0;
    margin: 3px 5px 5px 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}

/* 
  
  input, textarea, select {
    font-family: -apple-system, BlinkMacSystemFont, "Verdana", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    outline-color: var(--primary-color-darken);
    border: 1px solid var(--primary-text-color);
    border-radius: 2px;
}
  
input[type="text"], input[type="tel"]{
    padding: .6rem .5rem;
}
  
input:focus, textarea:focus, select:focus {
    border: 1px solid var(--primary-color-darken);
    outline-style: solid;
    outline-width: 2px;
}  */
