.pd-item .item-uid {
    width: calc((100% - var(--serial-width)) * 0.2);
}

.pd-item .sup-cus {
    width: calc((100% - var(--serial-width)) * 0.3);
}
.pd-item .date {
    width: calc((100% - var(--serial-width)) * 0.15);
}
.pd-item .status {
    width: calc((100% - var(--serial-width)) * 0.15);
}

.pd-item .totals {
    width: calc((100% - var(--serial-width)) * 0.15);
    text-align: right;
}
