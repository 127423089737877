.watermark {
    font-size: var(--text-error);
    color: var(--grey);
    line-height: 1.2rem;
    text-justify: right;
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
}

.watermark span {
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.watermark span span {
    margin-right: 0.5rem;
    /* font-size: 1.5rem; */
    opacity: 0;
}

.watermark > span:hover {
    /* text-decoration: underline; */
    color: var(--grey-d2);
    cursor: pointer;
    border: 1px solid var(--grey-l1);
}

.watermark span:hover span {
    opacity: 1;
}

/* 
    print media query is in app.css
*/
/* @media print {
    .watermark {
        display: none;
    }
} */

.audit-trail {
    font-size: var(--text-error);
    color: var(--grey-d1);
    line-height: 1.2rem;
    margin-top: 3rem;
    text-align: center;
}

.audit-trail span {
    width: 33%;
}
.audit-trail .audit-trail-heading,
.audit-trail .audit-trail-list {
    display: flex;
    justify-content: space-between;
}

.audit-trail .audit-trail-heading {
    color: var(--grey-d2);
    margin-bottom: 0.7rem;
    background-color: var(--grey-l1);
    padding: 0.5rem 0.2rem;
}
.audit-trail .audit-trail-list {
    margin-bottom: 0.5rem;
}

.audit-trail .audit-cancel-button {
    margin-bottom: 1rem;
    color: var(--grey-d2);
}

.audit-trail .audit-cancel-button span {
    border-radius: 2px;

    border: 1px solid var(--grey-l1);
    padding: 3px 7px;
    cursor: pointer;
}

.audit-trail .audit-cancel-button span:hover {
    /* text-decoration: underline; */
    background-color: var(--grey-l2);
    cursor: pointer;
}
