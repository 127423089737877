div.overlay {
    grid-area: overlay;

    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    font-size: inherit;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.3);
    /* background-color: transparent;  */

    height: 100vh;
    width: 100vw;

    z-index: 101;
}
