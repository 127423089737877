/*
  Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
*/
@font-face {
  font-family: 'FabricMDL2Icons';
  src: url('../fonts/fabric-icons-81e2bcce.woff') format('woff');
}

.ms-Icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'FabricMDL2Icons';
  font-style: normal;
  font-weight: normal;
  speak: none;
}

.ms-Icon--ReportDocument:before { content: "\E9F9"; }
