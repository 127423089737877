.chart-container {
    /* width: 48.5%; */
    width: 31.9%;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: var(--shadow);
    padding: 10px 20px;

    margin: 1rem;
    position: relative;
    background-color: #fff;
}

.chart-container .label {
    margin: 0.5rem auto 1rem;
    text-align: center;
    font-weight: bold;
}

.chart-table {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 1rem 0;
    border: 1px solid var(--grey-l1);
}

.chart-table .table-row {
    display: flex;
    flex-direction: row;
}
.chart-table .table-row div {
    display: flex;
    align-items: center;
    /* height: 2.8rem; */
    padding: 0.5rem 2rem;
}

.chart-table .table-row div:nth-of-type(2) {
    width: 30%;
}
.chart-table .table-row div:nth-of-type(1) {
    width: 70%;
}

.chart-table .table-heading {
    font-weight: 700;
}
.chart-table .table-items:nth-child(even) {
    background-color: var(--grey-l2);
}

@media screen and (max-width: 1675px) {
    .chart-container {
        width: 47.5%;
    }
}

@media screen and (max-width: 970px) {
    .chart-container {
        width: 60%;
    }
}

@media screen and (max-width: 800px) {
    .chart-container {
        width: 70%;
    }
}

@media screen and (max-width: 690px) {
    .chart-container {
        width: 90%;
    }
}
