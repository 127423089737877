/* Page Layout ==================================================== */

.content-layout {
    background-color: var(--grey-l2);
    height: 100%;
    padding: 0 3rem 2rem 3rem;
}

.content-layout .top-section {
    position: fixed;
    top: 6rem;
    left: 26.1rem;
    width: calc(100% - 26rem);
    background-color: var(--grey-l2);
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid var(--grey-l1);
}

.content-layout .main-section {
    padding-top: 10rem;
    position: relative;
}

@media screen and (max-width: 1200px) {
    .content-layout .top-section {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        padding: 1rem 0;
    }

    .content-layout .main-section {
        padding-top: 4rem;
    }
}

/* Form ======================================================== */
.common-form {
    background: var(--white);
    padding: 3rem 3rem 1rem 3rem;
    /* margin-top: 3rem; */
    box-shadow: var(--shadow);
    border-radius: 2px;
    border: 1px solid var(--grey-l1);
    max-width: 130rem;
    /* max-width: 110rem; */
}

.common-form .form-title-section {
    margin-bottom: 3rem;
    font-size: var(--text-pane-header);
    font-weight: 700;
}

.common-form .form-main-section {
    display: grid;
    column-gap: 2rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content;
}

.common-form .general-error-message {
    margin: 1rem 0 0 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}

.common-form .form-main-section .form-profile-info-items {
    min-height: 5rem;
    margin-bottom: 1rem;
    overflow: hidden;
}
.common-form .form-main-section .form-profile-info-items-tall {
    min-height: 8rem;
    margin-bottom: 2rem;
    overflow: hidden;
}
.common-form .form-main-section .fpii-label {
    font-size: var(--text-notice);
    line-height: var(--line-height-notice);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}
.common-form .form-main-section .fpii-text {
    font-size: var(--text-standard);
    line-height: var(--line-height-standard);
}

.fpii-text {
    font-size: var(--text-standard);
    line-height: var(--line-height-standard);
}

/* 
UI for getting items from db. datalist with add button.
*/

.common-form .form-main-section .get-item-from-db-container {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: var(--grey-l2);
    border: 1px solid var(--grey-l1);
}
.common-form .form-main-section .get-item-from-db-container .label {
    font-size: 1.6rem;
    display: inline-block;
    margin: 1rem 0;
}

.common-form .form-main-section .datalist-with-addbtn-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

/* 
    Subform definitions for purchase orders and similar pages.
*/
.subform {
    font-size: 1.4rem;
    margin-bottom: 2rem;
    border: 1px solid var(--grey);
    padding-bottom: 2rem;
    border-radius: 2px;
}

.subform .subform-label-qtys-section {
    background-color: var(--grey-l1);
    padding: 0.7rem 1rem;
    margin-bottom: 1rem;
    align-items: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subform .label {
    font-weight: 700;
    font-size: 1.4rem;
}
.subform .icon {
    margin-right: 1rem;
    font-size: 1.6rem;
}
.subform .subform-label-qtys-section .slqs-qty {
    font-weight: 700;
}

.subform .subform-2-column-section {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    margin: 0;
    padding: 0 1rem;
    align-items: center;
}

.subform .subform-list-section {
    margin-top: 2rem;
    padding: 0 1rem;
}

.subform .subform-list-section .sls-label {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    border-bottom: 1px solid var(--grey-l1);
}

.subform .subform-list-section .sls-items {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1rem;
    align-items: center;
    font-size: 1.6rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.subform .subform-list-section .sls-items > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subform .subform-list-section .sls-items .slsi-label {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.subform .subform-list-section .sls-items .slsi-label > div {
    margin-left: 1rem;
    width: 100%;
}
.subform .subform-list-section .sls-items .slsi-label > div > div {
    display: flex;
    align-items: center;
    font-size: var(--text-standard);
}

.subform
    .subform-list-section
    .sls-items
    .slsi-label
    > div
    > div:first-of-type {
    display: flex;
    align-items: center;
    font-size: var(--text-standard);
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.subform .subform-list-section .sls-items .slsi-label span {
    display: inline-block;
    margin-left: 1rem;
    border-radius: 100px;
}

.subform .subform-list-section .sls-items:nth-child(even) {
    background-color: var(--grey-l2);
}

.subform .subform-list-section .sls-items .slsi-inputs {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 1rem;
}

/* ================================================================ */

.common-form .form-button-section {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.common-form .confirm-delete-container {
    width: 18rem;
    margin-right: 1rem;
}
.common-form .confirm-delete-container label {
    color: var(--red);
}

.common-form .confirm-delete-container #delete-input {
    border: 1px solid var(--red);
    color: red;

    padding: 0.6rem;
    outline: none;
    width: 100%;
}

.common-form .confirm-delete-container #delete-input:focus {
    outline: 1px solid var(--red);
}

.common-form .form-button-section .middle-button-control {
    margin-left: auto;
}

.common-form .form-button-section .left-button-control {
    margin-right: auto;
}
.common-form .form-button-section .right-button-control {
    margin-left: 3rem;
}

@media screen and (max-width: 600px) {
    .content-layout {
        padding: 1rem;
    }
    .content-layout .top-section {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .content-layout .top-section button {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .common-form {
        padding: 1.5rem;
    }
    .common-form .form-button-section .right-button-control {
        margin-left: 1rem;
    }
}

/* 
sub-form that spans 2 grid columns
*/
.span-2-columns-container {
    grid-column: 1 / span 2;
    /* display: flex; */
}

.span-2-always {
    grid-column: 1 / span 2;
}

.mobile-label,
.mobile-view {
    display: none;
}

.desktop-view {
    display: flex;
}

.view-table-heading,
.view-table-heading-si,
.view-table-heading-operations {
    display: grid;
    column-gap: 3rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content;
    margin-bottom: 1rem;
    font-size: var(--text-notice);
    line-height: var(--line-height-notice);
    font-weight: 700;
    /* align-items: center; */
}

.view-table-row,
.view-table-row-si,
.view-table-row-operations {
    display: grid;
    column-gap: 3rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content;
    margin-bottom: 1rem;
    font-size: var(--text-standard);
    line-height: var(--line-height-standard);
    /* align-items: center; */
}

.grid-row-40-60 {
    grid-template-columns: minmax(0, 4fr) minmax(0, 6fr);
}

.view-table-row-si,
.view-table-heading-si {
    grid-template-columns: minmax(0, 35fr) minmax(0, 65fr);
}

.view-table-row-operations,
.view-table-heading-operations {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.cell {
    width: 100%;
    display: flex;
    align-items: center;
}

.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.space-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.center {
    margin: auto;
}

.width100 {
    width: 100%;
}

.width66 {
    width: 66%;
}
.width60 {
    width: 60%;
}

.width50 {
    width: 50%;
}
.width55 {
    width: 55%;
}
.width45 {
    width: 45%;
}
.width40 {
    width: 40%;
}
.width25 {
    width: 25%;
}
.width20 {
    width: 20%;
}
.width75 {
    width: 75%;
}
.width33 {
    width: 33%;
}
.width16 {
    width: 16%;
}
.width15 {
    width: 15%;
}
.width10 {
    width: 10%;
}

.width100 {
    width: 100%;
}

.mobile-m-top-2 {
    margin-top: 0;
}

.parent-labels {
    font-size: var(--text-notice);
    line-height: var(--line-height-notice);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.label-padding {
    padding: 0.5rem;
}

.label-highlight {
    background-color: var(--grey-l1);
}

.call-to-action-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.row {
    display: flex;
    justify-content: flex-end;
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-column-to-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-row-to-column {
    display: flex;
    align-items: center;
}

.datalist-addbutton-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
}

.icon-size {
    font-size: 1.6rem;
}

.sort-select {
    margin-left: 1rem;
    position: relative;
    top: -3px;
}

.amount-box {
    display: grid;
    column-gap: 3rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content;
    border: 1px solid var(--grey-l1);
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 2px;

    align-items: center;
}

.amount-box .rate-input {
    display: flex;
    align-items: center;
}

.font-size-13 {
    font-size: 1.3rem;
}

@media screen and (max-width: 720px) {
    .common-form .form-main-section {
        grid-template-columns: minmax(0, 1fr);
    }

    .span-2-columns-container {
        grid-column: 1 / span 1;
    }

    .mobile-label {
        display: inline-block;
        font-weight: 700;
        font-size: var(--text-notice);
    }

    .mobile-view {
        display: inline-block;
    }

    .desktop-view {
        display: none;
    }

    .view-table-heading,
    .view-table-heading-si,
    .view-table-heading-operations {
        display: none;
    }

    .view-table-row,
    .view-table-row-si,
    .view-table-row-operations {
        row-gap: 1rem;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: min-content;
        background-color: var(--grey-l2);
        padding: 1rem;
    }

    .cell {
        flex-wrap: wrap;
    }
    .width25,
    .width20,
    .width16,
    .width15,
    .width10,
    .width55,
    .width50,
    .width33,
    .width45,
    .width60,
    .width66,
    .width40,
    .width75 {
        width: 100%;
    }

    .common-form .form-button-section {
        display: flex;
        flex-wrap: wrap;
    }

    .mobile-m-top-2 {
        margin-top: 2rem;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .flex-row-to-column {
        flex-direction: column;
        align-items: flex-start;
    }
        .sort-select {
            margin-left: 0;
        }

    .flex-column-to-row {
        flex-direction: row;
        align-items: center;
    }

    .subform .subform-2-column-section {
        grid-template-columns: minmax(0, 1fr);
    }

    .subform .subform-list-section .sls-items {
        grid-template-columns: minmax(0, 1fr);
    }
    .subform .subform-list-section .sls-items .slsi-inputs {
        grid-template-columns: minmax(0, 1fr);
    }
}
