.appMessage {
    background-color: #5ef55e;
    font-size: var(--text-standard);
    line-height: var(--line-heigh-standard);
    color: var(--primary-text-color);
    display: flex;
    align-items: center;
    position: fixed;
    top: 9rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 4rem 1rem 2rem;
    min-width: 20rem;
    max-width: 90vw;
    border-radius: 2px;
    outline: transparent;
    box-shadow: var(--shadow-hover-high);
    z-index: 1000;
}

.appErrorMessage {
    background-color: #f75d59;
    color: var(--white);
}

.appMsg-icon-ctrl {
    padding: 0.5rem;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}

.detailed-messages-container {
    margin: 0;
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;
}

#detailed-messages-container::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    margin-left: 0.5rem;
}

#detailed-messages-container::-webkit-scrollbar {
    width: 2px;
    background-color: rgba(255, 255, 255, 0.1);
}

#detailed-messages-container::-webkit-scrollbar-thumb {
    background-color: var(--grey-l1);
}

.detailed-message-box {
    padding: 0.5rem 0 0.5rem 0.5rem;
    direction: ltr;
}

.detailed-message-box .main {
    margin-bottom: 0.5rem;
}

.detailed-message-box .details {
    margin: 0 0 0.5rem 1rem;
}

@media screen and (max-width: 500px) {
    .appMessage {
        width: 85%;
    }
}
