.signature-box {
    display: flex;
    justify-content: space-between;
    grid-column: 1 / span 2;
    margin-top: 5rem;
}

.signature-section {
    padding-top: 0.5rem;
    border-top: 1px solid black;
    font-weight: 700;
    width: 35%;
}
