.inventory-transfer .item-uid {
    width: calc((100% - var(--serial-width)) * 0.3);
}

.inventory-transfer .recepient-uid {
    width: calc((100% - var(--serial-width)) * 0.35);
}
.inventory-transfer .date {
    width: calc((100% - var(--serial-width)) * 0.15);
}
.inventory-transfer .status {
    width: calc((100% - var(--serial-width)) * 0.2);
}
