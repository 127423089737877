.setup-rm-item .rmtype {
    width: calc((100% - var(--serial-width)) * 0.3);
}
.setup-rm-item .rmdescription {
    width: calc((100% - var(--serial-width)) * 0.3);
}
.setup-rm-item .rmcode {
    width: calc((100% - var(--serial-width)) * 0.2);
}
.setup-rm-item .rmcategory {
    width: calc((100% - var(--serial-width)) * 0.2);
}
