/* List view layout ======================================= */

.common-list-view2 {
    display: flex;
    flex-direction: column;
    word-wrap: normal;
}

/* Pagination controls ========================= */
.pagination-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.list-view-bottom-filler {
    margin: 2rem;
}
.list-view-top-filler {
    margin: 4rem;
}

@media screen and (max-width: 1200px) {
    .list-view-top-filler {
        display: none;
    }
}
