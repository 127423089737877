@font-face {
    font-family: RobotoHosted;
    src: url(./font/Roboto-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: RobotoHosted;
    src: url(./font/Roboto-Bold.ttf);
    font-weight: 700;
}

/* 
  Colors
*/
:root {
    --blue-l2: #90caf9;
    --blue-l1: #42a5f5;
    --blue: #2196f3;
    --blue-d1: #1976d2;
    --blue-d2: #0d47a1;

    --sky-l2: #80d8ff;
    --sky-l1: #40c4ff;
    --sky: #00b0ff;
    --sky-d1: #0091ea;
    --sky-d2: #0081d1;

    --red-l2: #ffcdd2;
    --red-l1: #e57373;
    --red: #f44336;
    --red-d1: #e53935;
    --red-d2: #c62828;

    --green-l2: #b9f6ca;
    --green-l1: #69f0ae;
    --green: #00e676;
    --green-d1: #00c853;
    --green-d2: #00af48;

    --lime-l2: #ccff90;
    --lime-l1: #b2ff59;
    --lime: #76ff03;
    --lime-d1: #64dd17;
    --lime-d2: #5ac615;

    --amber-l2: #ffd54f;
    --amber-l1: #ffca28;
    --amber: #ffc107;
    --amber-d1: #ffb300;
    --amber-d2: #ffa000;

    --yellow-l2: #ffffa7;
    --yellow-l1: #ffff8d;
    --yellow: #ffff00;
    --yellow-d1: #ffea00;
    --yellow-d2: #ffd600;

    --purple-l2: #ea80fc;
    --purple-l1: #e040fb;
    --purple: #d500f9;
    --purple-d1: #aa00ff;
    --purple-d2: #9900e6;

    --grey-l2: #f5f5f5;
    --grey-l1: #e0e0e0;
    --grey: #9e9e9e;
    --grey-d1: #616161;
    --grey-d2: #212121;

    --black: #000000;
    --white: #ffffff;

    --primary-color: var(--blue);
    --primary-color-darken: var(--blue-d1);
    --primary-text-color: #141414;
    --secondary-text-color: var(--grey-d1);

    --text-error: 1.1rem;
    --line-height-error: 1.4rem;

    --text-notice: 1.2rem;
    --line-height-notice: 1.6rem;

    --text-standard: 1.4rem;
    --line-height-standard: 2rem;

    --text-subject: 1.6rem;
    --line-height-subject: 2.2rem;

    --text-team-header: 1.8rem;
    --line-height-team-header: 2.4rem;

    --text-pane-header: 2rem;
    --line-height-pane-header: 2.8rem;

    --text-marketing-header: 2.4rem;
    --line-height-marketing-header: 3.2rem;

    --text-page-header: 2.8rem;
    --line-height-page-header: 3.6rem;

    --text-hero: 4.2rem;
    --line-height-hero: 5.2rem;

    --text-banner: 6.8rem;
    --line-height-banner: 7.6rem;

    --serial-width: 8rem;

    --shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
        0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    --shadow-hover-low: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.133),
        0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    --shadow-hover-high: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132),
        0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    --shadow-dialog: 0px 25.6px 57.6px 0px rgba(0, 0, 0, 0.22),
        0px 4.8px 14.4px 0px rgba(0, 0, 0, 0.18);

    --transition-standard: 0.1s;
    --transition-menu: 0.2s;

    --page-padding: 3rem;
    --form-width: 43rem;
    --form-padding: 3rem;
    --sidenav-breakpoint: 840px;
}

html {
    font-size: 62.5%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: normal;
}

body {
    font-family: 'RobotoHosted', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-weight: 400;
    color: var(--primary-text-color);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* RESETS */
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    height: 100%;
    width: 100%;
}

::placeholder {
    color: var(--grey);
    font-size: 1.2rem;
}
