.navbar {
    grid-area: 'nav';
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    width: calc(100% - 26rem);
    border-bottom: 1px solid var(--grey-l1);
    color: var(--grey-d1);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 26rem;
    z-index: 100;
    box-shadow: var(--shadow);
}

.navbar .hamburger {
    display: none;
    font-size: 2.2rem;
    font-weight: 400;
    padding: 1rem;
    margin-right: 2rem;
    border-radius: 4px;
    cursor: pointer;
    z-index: 90;
}

.navbar .page-title {
    font-size: 2rem;
    padding: 2rem;
}

/* ======================= */

.profile-button-container {
    margin-right: 3rem;
    z-index: 10;
    width: 30rem;

    position: relative;
    display: flex;
    justify-content: flex-end;
}

.profile-button {
    display: inline-block;
    padding: 1rem 2rem;
    cursor: pointer;
}
.profile-button:hover {
    color: var(--primary-color);
}

.profile-button-container .drop-menu {
    position: absolute;
    top: 3.7rem;
    right: 0;
    border: 1px solid var(--grey);
    width: 60%;
    padding: 0.2rem 0.7rem 0.7rem 0.7rem;
    background-color: var(--white);
    border-radius: 2px;
    box-shadow: var(--shadow-hover-high);
}

.drop-menu .drop-items {
    margin-top: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s linear;
}

.drop-menu .drop-items:hover {
    background-color: var(--grey-l1);
}

.profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0);
}

/* ======================= */
/* ======================= */

@media screen and (max-width: 1200px) {
    .navbar {
        width: 100%;
        left: 0;

        position: relative;
        box-shadow: none;
    }

    .navbar .hamburger {
        display: block;
    }
    .profile-button-container {
        display: none;
    }
    .profile-overlay {
        display: none;
    }
}
