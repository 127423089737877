.dashcard {
    /* color:  */
    padding: 0;
    margin: 1rem;
    width: 29.5rem;
    height: 12rem;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: var(--shadow);

    display: flex;
    flex-direction: column;
    background-color: var(--white);
}

.dashcard .top-section-card {
    font-weight: 400;
    display: flex;
    justify-content: space-evenly;
    height: 12rem;
}

.dashcard .top-section-card .main-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 2rem;
}

.dashcard .top-section-card .main-heading {
    font-size: 1.7rem;
    text-align: right;
    color: var(--grey-d1);
}

.dashcard .top-section-card .main-text {
    font-size: 4rem;
}

.dashcard .top-section-card .top-icon {
    font-size: 7rem;

    /* color: red; */

    display: flex;
    align-items: center;
    padding-left: 20px;
}

/* .dashcard .bottom-section {
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 14px;
    border-top: 1px solid #e2dddd;
    padding-top: 5px;
    color: #b9b4b4;
}

.dashcard .bottom-icon i {
    font-size: 30px;
} */
