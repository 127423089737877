.sfg-datalist-container {
    display: grid;
    column-gap: 3rem;
    row-gap: 1rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content;
}

.sfg-datalist-container .input-wrapper {
    width: 100%;
}

.sfg-datalist-container .required-qty-input {
    display: flex;
    width: 100%;
    align-items: center;
}

.sfg-datalist-container .required-qty-input .input-wrapper:first-child {
    margin-right: 1rem;
}

.sfg-datalist-container i {
    font-size: 1.6rem;
    margin: 1rem 0 0 1rem;
    cursor: pointer;
}

.sfg-rm-section {
    grid-column: 1 / span 2;
}

.sfg-rm-section .rss-label {
    font-size: var(--text-notice);
    line-height: var(--line-height-notice);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: inline-block;
}

@media screen and (max-width: 720px) {
    .sfg-rm-section {
        grid-column: 1 / span 1;
    }
    .sfg-datalist-container {
        grid-template-columns: minmax(0, 1fr);
        row-gap: 0.5rem;
        margin-bottom: 2rem;
        background-color: var(--grey-l2);
        padding: 1rem;
    }
}
