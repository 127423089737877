.setup-rm-inventory-item .rmtype {
    width: calc((100% - var(--serial-width)) * 0.23);
}
.setup-rm-inventory-item .rmdescription {
    width: calc((100% - var(--serial-width)) * 0.23);
}
.setup-rm-inventory-item .rmunit {
    width: calc((100% - var(--serial-width)) * 0.1);
}
.setup-rm-inventory-item .rmcategory {
    width: calc((100% - var(--serial-width)) * 0.11);
}
.setup-rm-inventory-item .rmlowthreshold {
    width: calc((100% - var(--serial-width)) * 0.11);
    text-align: right;
}
.setup-rm-inventory-item .rmprojected-qty {
    width: calc((100% - var(--serial-width)) * 0.11);
    text-align: right;
}
.setup-rm-inventory-item .rmactual-qty {
    width: calc((100% - var(--serial-width)) * 0.11);
    text-align: right;
}
