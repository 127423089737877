.dashboard-card-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8rem;
}

.dashboard-chart-section {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 690px) {
    .dashboard-card-section {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 800px) {
    .dashboard-chart-section {
        flex-direction: column;
        align-items: center;
    }
}
