.setup-sup-cus-item .name {
    width: calc((100% - var(--serial-width)) * 0.5);
}

.setup-sup-cus-item .contact-person {
    width: calc((100% - var(--serial-width)) * 0.25);
}

.setup-sup-cus-item .contact-number {
    width: calc((100% - var(--serial-width)) * 0.25);
}
