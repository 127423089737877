.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  border-radius: 2px;
}

.spinner-wrapper .spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid var(--grey-l1);
  border-radius: 50%;
  border-top-color: var(--amber);
  animation: spin .5s linear infinite;
  /* -webkit-animation: spin 1s linear infinite; */
}

@keyframes spin {
    to { 
        transform: rotate(360deg); 
    }
}
/* @-webkit-keyframes spin {
    to { 
        -webkit-transform: rotate(360deg); 
    }
} */