#root {
    max-width: 200rem;
    margin: auto;
}

.app {
    font-size: var(--text-standard);
    display: flex;
    position: relative;
    transition: all var(--transition-menu);
    min-height: 62rem;
    /* height property makes the sticky sidenav not work. */
}

.app-sidenav-slide {
    top: 0;
    transform: translateX(-260px);
}

/* used in javascript */
.hide-all-overflow {
    overflow: hidden;
}

.sidenav-wrapper {
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footer {
    position: relative;
    height: 5rem;
    background-color: var(--grey-d2);
    color: var(--grey-l1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-on-print-only {
    display: none;
}

@media screen and (max-width: 1200px) {
    #root {
        overflow-x: hidden;
        position: relative;
    }
    .app {
        display: flex;
        flex-direction: row-reverse;
        width: calc(100vw + 260px);
    }
}

@media print {
    html {
        font-size: 60%;
    }

    .hide-on-print {
        display: none;
    }

    .show-on-print-only {
        display: flex;
    }

    .app .top-section,
    .list-table-heading,
    .app .sidenav-wrapper,
    .footer,
    .navbar,
    .common-form .form-button-section,
    .common-form .watermark,
    .common-form .audit-trail,
    .help-tip,
    .warning-tip,
    .info-tip {
        display: none;
    }

    .content-layout {
        background-color: #fff;
        height: 100%;
        padding: 0;
    }

    .content-layout .main-section {
        padding-top: 0;
    }

    .common-form {
        box-shadow: none;
        border: none;
    }

    .common-form .form-main-section {
        row-gap: 0.2rem;
    }

    .subform .subform-list-section .sls-items {
        grid-gap: 0.2rem;
    }

    .subform .subform-list-section .sls-items .slsi-inputs {
        grid-gap: 0.2rem;
    }

    .subform
        .subform-list-section
        .sls-items
        .slsi-label
        > div
        > div:first-of-type {
        margin-bottom: 0.2rem;
    }

    .view-table-heading,
    .view-table-heading-si,
    .view-table-heading-operations {
        row-gap: 0.2rem;
    }

    .view-table-row,
    .view-table-row-si,
    .view-table-row-operations {
        row-gap: 0.2rem;
    }

    .amount-box {
        row-gap: 0.2rem;
    }

    .pdpo-form-rm-items-preview {
        grid-gap: 0.2rem;
    }

    .common-form .form-title-section {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        font-weight: 700;
    }
    .common-form .form-main-section .form-profile-info-items {
        margin-bottom: 0.5rem;
    }
    .common-form .form-main-section .form-profile-info-items-tall {
        margin-bottom: 1rem;
    }

    .subform {
        margin-bottom: 1rem;
    }

    .subform .subform-label-qtys-section {
        margin-bottom: 0.5rem;
    }

    .subform .subform-list-section {
        margin-top: 1rem;
    }
    .subform .subform-list-section .sls-label {
        margin-bottom: 0.7rem;
    }

    .subform .subform-list-section .sls-items {
        margin-bottom: 0.5rem;
    }
}

/* Components */

.btn {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

    -webkit-font-smoothing: antialiased;
    font-size: 1.4rem;
    font-weight: 400;
    box-sizing: border-box;

    padding: 0.2rem 1.3rem 0 1.3rem;
    min-width: 8rem;
    height: 3.2rem;
    user-select: none;
    background-color: var(--white);
    color: var(--primary-text-color);
    outline: transparent;
    border: 1px solid var(--grey-d1);

    text-decoration: none;
    border-radius: 2px;
}

.btn:hover,
.btn:focus {
    background-color: var(--grey-l2);
}

.btn-primary {
    background-color: var(--primary-color);
    color: var(--white);
    border-color: var(--primary-color);
}
.btn-primary:hover,
.btn-primary:focus {
    background-color: var(--primary-color-darken);
    border-color: var(--primary-color-darken);
}
.btn-danger {
    background-color: var(--red);
    color: var(--white);
    border-color: var(--red);
}
.btn-danger:hover,
.btn-danger:focus {
    background-color: var(--red-d1);
    border-color: var(--red-d1);
}

.btn-subscribe {
    background-color: var(--green-d1);
    color: var(--white);
    border-color: var(--green-d1);
}

.btn-subscribe:hover,
.btn-subscribe:focus {
    background-color: var(--green-d2);
    border-color: var(--green-d2);
}

.btn-disable {
    cursor: default;
    background-color: var(--grey-l1);
    border-color: var(--grey-l1);
    color: var(--grey-d1);
}
.btn-disable:hover,
.btn-disable:focus {
    background-color: var(--grey-l1);
    border-color: var(--grey-l1);
    color: var(--grey-d1);
}

.btn-icon-margin {
    margin: 0 1rem 0 0;
}

@media screen and (max-width: 720px) {
    .btn {
        font-size: 1.3rem;
        padding: 0.2rem 0.7rem 0 0.7rem;
    }
}

/* icon classes */
.clickable-icon {
    cursor: pointer;
}

/* background classes */
.bg-grey-l1 {
    background-color: var(--grey-l1);
}

.bg-grey-l2 {
    background-color: var(--grey-l2);
}

/* general error message format */
.error-message {
    margin: 3px 5px 5px 0;
    color: var(--red);
    font-size: var(--text-error);
    line-height: 1.2rem;
}

/* margin utility */
.inline-block {
    display: inline-block;
}

.goto-left {
    margin-right: auto;
}
.goto-right {
    margin-left: auto;
}

.m-lr-15 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.m-lr-10 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.m-lr-5 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.m-left-15 {
    margin-left: 1.5rem;
}

.m-right-15 {
    margin-right: 1.5rem;
}

.m-right-5 {
    margin-right: 0.5rem;
}
.m-left-5 {
    margin-left: 0.5rem;
}

.m-right-10 {
    margin-right: 1rem;
}
.m-left-10 {
    margin-left: 1rem;
}

.m-top-10 {
    margin-top: 1rem;
}
.m-top-20 {
    margin-top: 2rem;
}
.m-top-auto {
    margin-top: auto;
}
.m-bottom-10 {
    margin-bottom: 1rem;
}
.m-zero {
    margin: 0;
}
.m-10 {
    margin: 1rem;
}

.show-border {
    border: 1px solid var(--grey-l1);
}

.show-border-darker {
    border: 1px solid var(--grey);
}

.show-border-bottom {
    border-bottom: 1px solid var(--grey-l1);
}

.pad-around-10 {
    padding: 1rem;
}

.pad-around-5 {
    padding: 0.5rem;
}

.pad-top-10 {
    padding-top: 1rem;
}

.pad-top-20 {
    padding-top: 2rem;
}

.pad-left-15 {
    padding-left: 1.5rem;
}
.pad-right-15 {
    padding-right: 1.5rem;
}

.height-35 {
    height: 3.5rem;
    display: flex;
    align-items: center;
}

.text-bold {
    font-weight: 700;
}
.uppercase {
    text-transform: uppercase;
}

.text-red {
    color: var(--red);
}

.text-primary-theme-color {
    color: var(--primary-color);
}

.text-warning {
    color: darkorange;
}

.text-warning-wrapper input {
    color: darkorange;
}

.text-normal {
    font-weight: 400;
    font-size: 1.3rem;
}

.font12 {
    font-size: 1.2rem;
}

.font14 {
    font-size: 1.4rem;
}

.tj-right {
    text-align: right;
}

.tj-left {
    text-align: left;
}

.text-link {
    color: var(--primary-color);
    font-size: 1.3rem;

    display: flex;
    align-items: center;

    height: 3rem;
    cursor: pointer;
}
.text-link:hover {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}
